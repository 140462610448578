import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import AdSense from 'react-adsense';
import { FaShare, FaLaugh, FaDrum, FaArrowAltCircleRight } from 'react-icons/fa';
import { PulseLoader } from 'react-spinners';

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const AppContainer = styled.div`
  text-align: center;
  overflow: hidden;
  minimum-height: 100vh;
`;

const AppHeader = styled.header`
  background-color: #282c34;
  padding: 20px;
  color: white;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 24px;
  margin: 0;
`;

const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  margin: 10px;
`;

const LoadingWrapper = styled.div`
  animation: ${fadeInAnimation} 0.5s ease-in-out;
`;

const JokeContainer = styled.div`
  position: relative;
`;

const Setup = styled.div`
  font-size: 24px;
  animation: ${fadeInAnimation} 0.5s ease-in-out;
  ${props =>
    props.hidden &&
    css`
      display: none;
    `}
`;

const Punchline = styled.div`
  font-size: 24px;
  height: 45px;
  animation: ${fadeInAnimation} 0.5s ease-in-out;
  margin-top: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RevealButton = styled.button`
  font-size: 24px;
  height: 45px;
  animation: ${fadeInAnimation} 0.5s ease-in-out;
  margin-top: 20px;
  background-color: #36d7b7;
  border: none;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  cursor: pointer;
`;

const Footer = styled.footer`
  background-color: #282c34;
  padding: 10px;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
`;

const LinkedInLink = styled.a`
  color: white;
`;

const ActionWrapper = styled.div`
  bottom: 50px;
  position: relative;
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const ActionButton = styled.button`
  display: flex;
  padding: 10px;
  gap: 5px;
`;

const year = new Date().getFullYear();
const linkedinUrl = 'https://www.linkedin.com/in/sam-smith-60619369/';

const App = () => {
  const [joke, setJoke] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPunchline, setShowPunchline] = useState(false);

  const isFirstMount = useRef(true);

  useEffect(() => {
    if (isFirstMount.current) {
      fetchJoke();
      isFirstMount.current = false;
    }
  }, []);

  const fetchJoke = async () => {
    setShowPunchline(false);
    setLoading(true);
    try {
      let jokeData = null;
      while (!jokeData || jokeData.type === 'programming') {
        const response = await fetch('https://official-joke-api.appspot.com/jokes/random');
        jokeData = await response.json();
      }
      setJoke(jokeData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching joke:', error);
    }
  };

  const handleRevealAnswer = () => {
    setShowPunchline(true);
  };

  const playRimshot = () => {
    var audio = document.getElementById("rimshot");
    audio.play();
  }

  const playLaughTrack = () => {
    var audio = document.getElementById("laughtrack");
    audio.play();
  }

  return (
    <AppContainer>
      <AppHeader>
        <Title>JokeOfTheDay.app</Title>
      </AppHeader>
      <Main>
        {loading ? (
          <LoadingWrapper>
            <PulseLoader color="#36d7b7" />
          </LoadingWrapper>
        ) : (
          <JokeContainer>
            <Setup>{joke?.setup}</Setup>
            {showPunchline && 
              <div>
                <Punchline>{joke?.punchline}</Punchline>
              </div>
            }
            {!showPunchline && (
              <RevealButton onClick={handleRevealAnswer}>See Punchline</RevealButton>
            )}
          </JokeContainer>
        )}
      </Main>
      {showPunchline && 
        <ActionWrapper>
          <ActionButton onClick={playRimshot}>
            <FaDrum size={25}/>
          </ActionButton>
          <ActionButton onClick={playLaughTrack}>
            <FaLaugh size={25}/>
          </ActionButton>
          {/* <ActionButton>
            <FaShare size={25}/> Share Joke
          </ActionButton> */}
          <ActionButton onClick={fetchJoke}>
            <FaArrowAltCircleRight size={25}/>
          </ActionButton>
        </ActionWrapper>
      }
      <AdSense.Google client='ca-pub-9556916343730166' slot='5767210617'/>
      <Footer>
        &copy; {year} - <LinkedInLink href={linkedinUrl} target="_blank" rel="noopener noreferrer">Sam Smith</LinkedInLink>
      </Footer>
      <>
        <audio id='rimshot' src="/rimshot.mp3" />
        <audio id="laughtrack"  src="/laughtrack.mp3"/>
      </>
    </AppContainer>
  );
};

export default App;
